export const GA_TRACKING_ID = 'G-X8S6XWJ0ZT'

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
// export const pageview = url => {
//   window.gtag('config', GA_TRACKING_ID, {
//     page_location: url
//   })
// }

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (eventname) => {
  window.plausible(eventname) 
}