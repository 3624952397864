import React from "react"
import { Helmet } from "react-helmet"

interface Props {
  title: string
  description: string
}

const SEO: React.FC<Props> = ({ title, description }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="Description" content={description} />
      <meta charSet="utf-8" />
      <meta property="og:image:width" content="600" />
      <meta property="og:image:height" content="314" />
      <meta property="og:image" content="logo.png"/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content={title}/>
      <meta name="twitter:description" content={description}/>
      {/* <meta property="og:URL" content="%PUBLIC_URL%/logo.png" />
      <meta property="og:type" content="article" />
          
      <meta property="og:title" content={title} />
          
      <meta property="og:description" content={description} />

      <meta property="og:image" content="%PUBLIC_URL%/logo.png" /> */}
    </Helmet>
  )
}

export default SEO
