// metadata
export const version = "0.8.13"
export const title = "Structs"
export const description = "Ein Beispiel wie structs in Solidity verwendet werden können"

const html = `<p>Mit <code>struct</code> können eigene Datentypen anlegt werden.</p>
<p>Ein <code>Struct</code> sind sinnvoll, wenn primtive Datentypen in einer bestimmten Beziehung stehen.</p>
<p>Ähnlich wie Enums kann ein <code>struct</code> auch außerhalb eines Contracts deklariert werden, um es dann anschließend in seinen eigentlichen Contract zu importieren. </p>
<p>Trotzdem ist es möglich <code>structs</code> innerhalb eines Contracts zu deklarieren und zu verwenden. </p>
<pre><code class="language-solidity"><span class="hljs-comment">// SPDX-License-Identifier: MIT</span>
<span class="hljs-meta"><span class="hljs-keyword">pragma</span> <span class="hljs-keyword">solidity</span> ^0.8.13;</span>

<span class="hljs-class"><span class="hljs-keyword">contract</span> <span class="hljs-title">Todos</span> </span>{
    <span class="hljs-keyword">struct</span> <span class="hljs-title">Todo</span> {
        <span class="hljs-keyword">string</span> text;
        <span class="hljs-keyword">bool</span> completed;
    }

    <span class="hljs-comment">// Ein array mit mehreren &#x27;Todo&#x27; structs</span>
    Todo[] <span class="hljs-keyword">public</span> todos;

    <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">create</span>(<span class="hljs-params"><span class="hljs-keyword">string</span> <span class="hljs-keyword">calldata</span> _text</span>) <span class="hljs-title"><span class="hljs-keyword">public</span></span> </span>{
        <span class="hljs-comment">// 3 Wege um ein struct zu initialisieren</span>
        <span class="hljs-comment">// - Struct aufrufen, wie eine Funktion</span>
        todos.<span class="hljs-built_in">push</span>(Todo(_text, <span class="hljs-literal">false</span>));

        <span class="hljs-comment">// Alternative 1</span>
        todos.<span class="hljs-built_in">push</span>(Todo({text: _text, completed: <span class="hljs-literal">false</span>}));

        <span class="hljs-comment">// Initialisere ein leeres Struct und dann überschreibe die einzelnen Variablen innerhalb des Structs</span>
        Todo <span class="hljs-keyword">memory</span> todo;
        todo.text <span class="hljs-operator">=</span> _text;
        <span class="hljs-comment">// todo.completed initialisiert mit dem Default-Wert false</span>

        todos.<span class="hljs-built_in">push</span>(todo);
    }

    <span class="hljs-comment">// Solidity erstellt automatisch eine "getter"-/Rückgabe-Funktion um die Werte zu erhalten.</span>
    <span class="hljs-comment">// Diese Funktion muss daher nicht selbst geschrieben werden</span>
    <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">get</span>(<span class="hljs-params"><span class="hljs-keyword">uint</span> _index</span>) <span class="hljs-title"><span class="hljs-keyword">public</span></span> <span class="hljs-title"><span class="hljs-keyword">view</span></span> <span class="hljs-title"><span class="hljs-keyword">returns</span></span> (<span class="hljs-params"><span class="hljs-keyword">string</span> <span class="hljs-keyword">memory</span> text, <span class="hljs-keyword">bool</span> completed</span>) </span>{
        Todo <span class="hljs-keyword">storage</span> todo <span class="hljs-operator">=</span> todos[_index];
        <span class="hljs-keyword">return</span> (todo.text, todo.completed);
    }

    <span class="hljs-comment">// update den text in einem struct, in dem Struct-Array</span>
    <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">updateText</span>(<span class="hljs-params"><span class="hljs-keyword">uint</span> _index, <span class="hljs-keyword">string</span> <span class="hljs-keyword">calldata</span> _text</span>) <span class="hljs-title"><span class="hljs-keyword">public</span></span> </span>{
        Todo <span class="hljs-keyword">storage</span> todo <span class="hljs-operator">=</span> todos[_index];
        todo.text <span class="hljs-operator">=</span> _text;
    }

    <span class="hljs-comment">// update den completd-Zustand in einem struct, in dem Struct-Array</span>
    <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">toggleCompleted</span>(<span class="hljs-params"><span class="hljs-keyword">uint</span> _index</span>) <span class="hljs-title"><span class="hljs-keyword">public</span></span> </span>{
        Todo <span class="hljs-keyword">storage</span> todo <span class="hljs-operator">=</span> todos[_index];
        todo.completed <span class="hljs-operator">=</span> <span class="hljs-operator">!</span>todo.completed;
    }
}
</code></pre>
<h3 id="deklarieren-und-importieren-eines-structs">Deklarieren und Importieren eines Structs</h3>
<p>Datei, wo das <code>Struct</code> deklariert wird:</p>
<pre><code class="language-solidity"><span class="hljs-comment">// SPDX-License-Identifier: MIT</span>
<span class="hljs-meta"><span class="hljs-keyword">pragma</span> <span class="hljs-keyword">solidity</span> ^0.8.13;</span>
<span class="hljs-comment">// Datei wird abgespeichert mit dem Namen &#x27;StructDeclaration.sol&#x27;</span>

<span class="hljs-keyword">struct</span> <span class="hljs-title">Todo</span> {
    <span class="hljs-keyword">string</span> text;
    <span class="hljs-keyword">bool</span> completed;
}
</code></pre>
<p>Datei, welche das <code>Struct</code> importiert:</p>
<pre><code class="language-solidity"><span class="hljs-comment">// SPDX-License-Identifier: MIT</span>
<span class="hljs-meta"><span class="hljs-keyword">pragma</span> <span class="hljs-keyword">solidity</span> ^0.8.13;</span>

<span class="hljs-keyword">import</span> <span class="hljs-string">"./StructDeclaration.sol"</span>;

<span class="hljs-class"><span class="hljs-keyword">contract</span> <span class="hljs-title">Todos</span> </span>{
    <span class="hljs-comment">// Initialisieren eines arrays, welches mit &#x27;Todo&#x27; structs befühlt werden können.</span>
    Todo[] <span class="hljs-keyword">public</span> todos;
}
</code></pre>
`

export default html
