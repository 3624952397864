import React, { useState } from "react"
import MailchimpSubscribe, { DefaultFormFields } from "react-mailchimp-subscribe"
import styled from "styled-components"
import styles from "./NewsletterModal.module.css"
import Button from "./Button"
import CloseIcon from "./CloseIcon"
import MailSentState from "./MailSentState"

export interface NewsletterModalProps {
  onClose: () => void
}

export default function NewsletterModal({ onClose }: NewsletterModalProps) {
  const [email, setEmail] = useState("")
  const [checked, setChecked] = useState(false)

  function onSubmit(
    event: React.FormEvent<HTMLFormElement>,
    enrollNewsletter: (props: DefaultFormFields) => void
  ) {
    event.preventDefault()
    console.log({ email })
    console.log(enrollNewsletter)
    if (email) {
      enrollNewsletter({ EMAIL: email })
    }
  }

  return (
    <Overlay onClick={onClose}>
      <CloseIconContainer>
        <CloseIcon onClick={onClose} />
      </CloseIconContainer>
      <iframe
        src="https://simplekieth.substack.com/embed"
        width="480"
        height="320"
        // frameborder="0"
        scrolling="no"
      ></iframe>
    </Overlay>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
`

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: white;
`

const Card = styled.form`
  display: flex;
  position: relative;
  flex-direction: column;
  margin: auto;
  padding: 10rem 5rem;
  background: rgb(var(--modalBackground));
  border-radius: 0.6rem;
  // max-width: 50vw;
  overflow: hidden;
  color: white;

  @media (max-width: 800px) {
    padding: 5rem 2rem;
    // max-width: 80vw;
    // min-width: 100vw;
  }
`

const CloseIconContainer = styled.div`
  position: absolute;
  right: 2rem;
  top: 2rem;

  svg {
    cursor: pointer;
    width: 2rem;
  }
`

const Title = styled.div`
  font-size: 4.2rem;
  font-weight: bold;
  line-height: 1.1;
  letter-spacing: -0.03em;
  text-align: center;
  color: lightblue;

  @media (max-width: 800px) {
    font-size: 3.2rem;
  }
`

const ErrorMessage = styled.p`
  color: rgb(var(--errorColor));
  font-size: 1.5rem;
  margin: 1rem 0;
  text-align: center;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 100%;
  // width: 100%;
  // width: 50vw;
  margin-top: 3rem;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`

const CustomButton = styled.button`
  height: 100%;
  padding: 1.8rem;
  color: white;
  font-size: 1.6rem;
  border: 1px solid gray;
  background: rgb(var(--primary));
  margin-left: 1.5rem;
  border-radius: 0.6rem;

  &:hover {
    box-shadow: green 0 0 0 0.1rem;
    color: green;
  }
  @media (max-width: 800px) {
    margin-left: 0;
    margin-top: 1.5rem;
  }
`

// const Input = styled.input``

const Text = styled.div`
  // padding: 1.8rem;
  // margin-left: 1rem;
  width: 50%;
  margin: auto;

  // box-shadow: white 0 0 0 0.1rem;
  font-size: 0.8rem;
  color: white;
  // text-align: center;

  & a {
    color: gray;
  }

  & h3 {
    font-size: 1.2rem;
    font-weight: bold;
  }

  & p {
    font-size: 1rem;
  }
  & span {
    font-size: 1rem;
  }
`

const CustomInput = styled.input`
  color: white;
  height: 100%;
  border: 1px solid gray;
  background: rgb(var(--inputBackground));
  border-radius: 0.6rem;
  font-size: 1.6rem;
  padding: 1.8rem;
  box-shadow: white 0 0 0 0.1rem;
  /* color: rgb(var(--textSecondary)); */

  &:focus {
    outline: none;
    box-shadow: green 0 0 0 0.1rem;
  }

  // media queries
  @media (max-width: 800px) {
    min-width: 90%;
  }
`
