// metadata
export const version = "0.8.13"
export const title = "Datenschutzerklärung"
export const description = "Datenschutzerklärung | Lern Solidity"

const html = `<h2 id="1-datenschutz-auf-einen-blick"><strong>1. Datenschutz auf einen Blick</strong></h2>
<h2 id="allgemeine-hinweise">Allgemeine Hinweise</h2>
<p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen
Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.</p>
<h2 id="datenerfassung-auf-dieser-website">Datenerfassung auf dieser Website</h2>
<p>Wer ist verantwortlich für die Datenerfassung auf dieser Website?
Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten
können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.</p>
<h2 id="wie-erfassen-wir-ihre-daten">Wie erfassen wir Ihre Daten?</h2>
<p>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular eingeben. Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere ITSysteme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.</p>
<h2 id="wofür-nutzen-wir-ihre-daten">Wofür nutzen wir Ihre Daten?</h2>
<p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.</p>
<h2 id="welche-rechte-haben-sie-bezüglich-ihrer-daten">Welche Rechte haben Sie bezüglich Ihrer Daten?</h2>
<p>Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</p>
<p>Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.</p>
<h2 id="analyse-tools-und-tools-von-drittanbietern">Analyse-Tools und Tools von Drittanbietern</h2>
<p>Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor
allem mit sogenannten Analyseprogrammen.
Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden
Datenschutzerklärung.</p>
<h2 id="2-hosting"><strong>2. Hosting</strong></h2>
<p>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</p>
<h2 id="externes-hosting">Externes Hosting</h2>
<p>Diese Website wird extern gehostet. Die personenbezogenen Daten, die auf dieser Website erfasst werden, werden auf den Servern des Hosters / der Hoster gespeichert. Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten, die über eine Website generiert werden, handeln.
Das externe Hosting erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und
bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).</p>
<p>Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung
von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im
Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner
Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.</p>
<p>Wir setzen folgende(n) Hoster ein:</p>
<p>Vercel Inc.</p>
<p>340 S Lemon Ave #4133</p>
<p>Walnut, CA 91789</p>
<h2 id="3-allgemeine-hinweise-und-pflichtinformationen"><strong>3. Allgemeine Hinweise und Pflichtinformationen</strong></h2>
<h2 id="datenschutz">Datenschutz</h2>
<p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie
dieser Datenschutzerklärung.</p>
<p>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie
und zu welchem Zweck das geschieht.</p>
<p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail)
Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
möglich.</p>
<h1 id="hinweis-zur-verantwortlichen-stelle">Hinweis zur verantwortlichen Stelle</h1>
<p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
<p>SimpleKI GmbH</p>
<p>Appellandstrasse 6</p>
<p>26419 Schortens</p>
<p>Telefon: +49 44 61-759082</p>
<p>E-Mail: <a href="mailto:&#x73;&#x75;&#x70;&#112;&#111;&#x72;&#116;&#x40;&#115;&#x69;&#x6d;&#x70;&#108;&#x65;&#107;&#x69;&#46;&#100;&#x65;">&#x73;&#x75;&#x70;&#112;&#111;&#x72;&#116;&#x40;&#115;&#x69;&#x6d;&#x70;&#108;&#x65;&#107;&#x69;&#46;&#100;&#x65;</a></p>
<p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über
die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.)
entscheidet.</p>
<h2 id="speicherdauer">Speicherdauer</h2>
<p>Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben
Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen,
werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer
personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im
letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.</p>
<h2 id="allgemeine-hinweise-zu-den-rechtsgrundlagen-der-datenverarbeitung-auf-dieser-website">Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</h2>
<p>Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf
Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien
nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung
personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art.
49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in
Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich
auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre
Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese
zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO.
Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden
Absätzen dieser Datenschutzerklärung informiert.</p>
<h2 id="hinweis-zur-datenweitergabe-in-die-usa-und-sonstige-drittstaaten">Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten</h2>
<p>Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen
datenschutzrechtlich nicht sicheren Drittstaaten. Wenn diese Tools aktiv sind, können Ihre
personenbezogene Daten in diese Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf
hin, dass in diesen Ländern kein mit der EU vergleichbares Datenschutzniveau garantiert werden kann.
Beispielsweise sind US-Unternehmen dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden
herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht
ausgeschlossen werden, dass US-Behörden (z. B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese
Verarbeitungstätigkeiten keinen Einfluss.</p>
<h2 id="widerruf-ihrer-einwilligung-zur-datenverarbeitung">Widerruf Ihrer Einwilligung zur Datenverarbeitung</h2>
<p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
Datenverarbeitung bleibt vom Widerruf unberührt.</p>
<h2 id="widerspruchsrecht-gegen-die-datenerhebung-in-besonderen-fällen-sowie-gegen-direktwerbung-art-21-dsgvo">Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)</h2>
<p>WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO
ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN
SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN
WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN,
WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE
VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).</p>
<p>WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN,
SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN
VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH
NACH ART. 21 ABS. 2 DSGVO).</p>
<h2 id="beschwerderecht-bei-der-zuständigen-aufsichtsbehörde">Beschwerderecht bei der zuständigen Aufsichtsbehörde</h2>
<p>Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger
verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</p>
<h2 id="recht-auf-datenübertragbarkeit">Recht auf Datenübertragbarkeit</h2>
<p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
<h2 id="auskunft-löschung-und-berichtigung">Auskunft, Löschung und Berichtigung</h2>
<p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den
Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie
zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.</p>
<h2 id="recht-auf-einschränkung-der-verarbeitung">Recht auf Einschränkung der Verarbeitung</h2>
<p>Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
folgenden Fällen:</p>
<ul>
<li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir
in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
<li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie
statt der Löschung die Einschränkung der Datenverarbeitung verlangen.
Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der
Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
<li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen
Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
zu verlangen.</li>
<li>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von
ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder
eines Mitgliedstaats verarbeitet werden.</li>
</ul>
<h2 id="ssl--bzw-tls-verschlüsselung">SSL- bzw. TLS-Verschlüsselung</h2>
<p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum
Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLSVerschlüsselung.
Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von
„http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>
<p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht
von Dritten mitgelesen werden.</p>
<h2 id="widerspruch-gegen-werbe-e-mails">Widerspruch gegen Werbe-E-Mails</h2>
<p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von
nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die
Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
Werbeinformationen, etwa durch Spam-E-Mails, vor.</p>
<h2 id="4-datenerfassung"><strong>4. Datenerfassung</strong></h2>
<h2 id="cookies">Cookies</h2>
<p>Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Datenpakete und richten auf
Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung
(Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies
werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät
gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.</p>
<p>Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät gespeichert werden, wenn Sie
unsere Seite betreten (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung bestimmter
Dienstleistungen des Drittunternehmens (z. B. Cookies zur Abwicklung von Zahlungsdienstleistungen).</p>
<p>Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte
Websitefunktionen ohne diese nicht funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige
von Videos). Andere Cookies dienen dazu, das Nutzerverhalten auszuwerten oder Werbung anzuzeigen.
Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs, zur Bereitstellung
bestimmter, von Ihnen erwünschter Funktionen (z. B. für die Warenkorbfunktion) oder zur Optimierung der
Website (z. B. Cookies zur Messung des Webpublikums) erforderlich sind (notwendige Cookies), werden auf
Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird.
Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von notwendigen Cookies zur
technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur
Speicherung von Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt die
Verarbeitung ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1
TTDSG); die Einwilligung ist jederzeit widerrufbar.
Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und
Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen
sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der
Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden, werden wir Sie hierüber
im Rahmen dieser Datenschutzerklärung gesondert informieren und ggf. eine Einwilligung abfragen.</p>
<h2 id="kontaktformular">Kontaktformular</h2>
<p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage
und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre
Einwilligung weiter.
Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der
effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit
widerrufbar.
Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung
auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
(z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen –
insbesondere Aufbewahrungsfristen – bleiben unberührt.</p>
<h2 id="5-soziale-medien"><strong>5. Soziale Medien</strong></h2>
<h2 id="twitter">Twitter</h2>
<p>Auf dieser Website sind Funktionen des Dienstes Twitter eingebunden. Diese Funktionen werden
angeboten durch die Twitter International Company, One Cumberland Place, Fenian Street, Dublin 2, D02
AX07, Irland.
Wenn das Social-Media-Element aktiv ist, wird eine direkte Verbindung zwischen Ihrem Endgerät und dem
Twitter-Server hergestellt. Twitter erhält dadurch Informationen über den Besuch dieser Website durch Sie.
Durch das Benutzen von Twitter und der Funktion „Re-Tweet“ werden die von Ihnen besuchten Websites mit
Ihrem Twitter-Account verknüpft und anderen Nutzern bekannt gegeben. Wir weisen darauf hin, dass wir
als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch</p>
<p>Twitter erhalten. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Twitter unter:
<a href="https://twitter.com/de/privacy">https://twitter.com/de/privacy</a>.
Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der Einsatz des o. g. Dienstes auf Grundlage von
Art. 6 Abs. 1 lit. a DSGVO und § 25 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Soweit keine
Einwilligung eingeholt wurde, erfolgt die Verwendung des Dienstes auf Grundlage unseres berechtigten
Interesses an einer möglichst umfassenden Sichtbarkeit in den Sozialen Medien.
Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt.
Details finden Sie hier:
<a href="https://gdpr.twitter.com/en/controller-to-controller-transfers.html">https://gdpr.twitter.com/en/controller-to-controller-transfers.html</a>.
Ihre Datenschutzeinstellungen bei Twitter können Sie in den Konto-Einstellungen unter
<a href="https://twitter.com/account/settings">https://twitter.com/account/settings</a> ändern.</p>
<h2 id="6-newsletter"><strong>6. Newsletter</strong></h2>
<h2 id="newsletterdaten">Newsletterdaten</h2>
<p>Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen eine EMail-
Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der Inhaber der
angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind. Weitere
Daten werden nicht bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir ausschließlich für
den Versand der angeforderten Informationen und geben diese nicht an Dritte weiter.
Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt ausschließlich auf
Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der
Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters können Sie jederzeit
widerrufen, etwa über den „Austragen“-Link im Newsletter. Die Rechtmäßigkeit der bereits erfolgten
Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer
Austragung aus dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der
Abbestellung des Newsletters oder nach Zweckfortfall aus der Newsletterverteilerliste gelöscht. Wir
behalten uns vor, E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem Ermessen im Rahmen
unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO zu löschen oder zu sperren.
Daten, die zu anderen Zwecken bei uns gespeichert wurden, bleiben hiervon unberührt.
Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns bzw. dem
Newsletterdiensteanbieter ggf. in einer Blacklist gespeichert, sofern dies zur Verhinderung künftiger
Mailings erforderlich ist. Die Daten aus der Blacklist werden nur für diesen Zweck verwendet und nicht mit
anderen Daten zusammengeführt. Dies dient sowohl Ihrem Interesse als auch unserem Interesse an der
Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes Interesse im Sinne des
Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht befristet. Sie können der
Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes Interesse überwiegen.</p>
<h2 id="mailchimp-datenschutzerklarung">MailChimp Datenschutzerklarung</h2>
<p>Genauso wie viele andere Webseiten, verwenden wir auch auf unserer Website die Dienste des Newsletter Unternehmens MailChimp. Der Betreiber von MailChimp ist das Unternehmen The Rocket Science  Group, LLC, 675 Ponce de Leon Ave NE, Suite 5000, Atlanta, GA 30308 USA. Dank MailChimp können wir  Ihnen interessante Neuigkeiten sehr einfach per Newsletter zukommen lassen. Mit MailChimp müssen  wir nichts installieren und können trotzdem aus einem Pool an wirklich nützlichen Funktionen schöpfen.  Im Folgenden gehen wir näher auf dieses E-Mail-Marketing-Service ein und informieren Sie über die  wichtigsten datenschutzrelevanten Aspekte.</p>
<h2 id="was-ist-mailchimp">Was ist MailChimp? </h2>
<p>MailChimp ist ein cloudbasiertes Newsletter-Management-Service. „Cloudbasiert“ heißt, dass wir  MailChimp nicht auf unserem eigenen Rechner bzw. Server installieren müssen. Wir nutzen den Dienst  stattdessen über eine IT-Infrastruktur – die über das Internet verfügbar ist – auf einem externen Server.  Diese Art eine Software zu nutzen, wird auch SaaS (Software as a Service) genannt. </p>
<p>Mit MailChimp können wir aus einer breiten Palette an verschiedenen E-Mail-Typen auswählen.  Abhängig davon, was wir mit unserem Newsletter erreichen wollen, können wir Einzel-Kampagnen,  regelmäßige Kampagnen, Autoresponder (automatische E-Mails), A/B Tests, RSS-Kampagnen  (Aussendung in vordefinierter Zeit und Häufigkeit) und Follow-Up Kampagnen durchführen. </p>
<h2 id="warum-verwenden-wir-mailchimp-auf-unserer-webseite">Warum verwenden wir MailChimp auf unserer Webseite? </h2>
<p>Grundsätzlich nutzen wir einen Newsletter-Dienst, damit wir mit Ihnen in Kontakt bleiben. Wir wollen  Ihnen erzählen was es bei uns Neues gibt oder welche attraktiven Angebote wir gerade in unserem  Platfom haben. Für unsere Marketing-Maßnahmen suchen wir immer die einfachsten und besten  Lösungen. Und aus diesem Grund haben wir uns auch für das Newsletter-Management-Service von  Mailchimp entschieden. Obwohl die Software sehr einfach zu bedienen ist, bietet sie eine große Anzahl  an hilfreichen Features. So können wir in nur kurzer Zeit interessante und schöne Newsletter gestalten.  Durch die angebotenen Designvorlagen gestalten wir jeden Newsletter ganz individuell und dank des  „Responsive Design“ werden unsere Inhalte auch auf Ihrem Smartphone (oder anderen mobilen  Endgeräten) leserlich und schön angezeigt. </p>
<p>Durch Tools wie beispielsweise den A/B-Test oder den umfangreichen Analysemöglichkeiten, sehen wir  sehr schnell, wie unsere Newsletter bei Ihnen ankommen. So können wir gegebenenfalls reagieren und  unser Angebot oder unsere Dienstleistungen verbessern. </p>
<p>Ein weiterer Vorteil ist das „Cloudsystem“ von Mailchimp. Die Daten werden nicht direkt auf unserem  Server abgelegt und verarbeitet. Wir können die Daten von externen Servern abrufen und schonen auf  diese Weise unseren Speicherplatz. Zudem wird der Pflegeaufwand deutlich geringer. </p>
<h2 id="welche-daten-werden-von-mailchimp-gespeichert">Welche Daten werden von MailChimp gespeichert? </h2>
<p>Die Rocket Science Group LLC (MailChimp) unterhält Online-Plattformen, die es uns ermöglichen, mit  Ihnen (sofern Sie unseren Newsletter abonniert haben) in Kontakt zu treten. Wenn Sie über unsere  Website Abonnent unseres Newsletters werden, bestätigen Sie per E-Mail die Mitgliedschaft in einer E Mail-Liste von MailChimp. Damit MailChimp auch nachweisen kann, dass Sie sich in den „Listenprovider“  eingetragen haben, werden das Datum der Eintragung und Ihre IP-Adresse gespeichert. Weiters speichert MailChimp Ihre E-Mail-Adresse, Ihren Namen, die physische Adresse und demografische  Informationen, wie Sprache oder Standort. </p>
<p>Diese Informationen werden verwendet, um Ihnen E-Mails zu senden und bestimmte andere MailChimp Funktionen (wie z.B. Auswertung der Newsletter) zu ermöglichen. </p>
<p>MailChimp teilt Informationen auch mit Drittanbietern, um bessere Dienste bereitzustellen. Einige Daten  teilt MailChimp auch mit Werbepartnern von Drittanbietern, um die Interessen und Anliegen seiner  Kunden besser zu verstehen, damit relevantere Inhalte und zielgerichtete Werbung bereitgestellt  werden können. </p>
<p>Durch sogenannte „Web Beacons“ (das sind kleine Grafiken in HTML-E-Mails) kann MailChimp  feststellen, ob die E-Mail angekommen ist, ob sie geöffnet wurde und ob Links angeklickt wurden. All  diese Informationen werden auf den MailChimp-Servern gespeichert. Dadurch erhalten wir statistische  Auswertungen und sehen genau, wie gut unser Newsletter bei Ihnen ankam. Auf diese Weise können wir  unser Angebot viel besser an Ihre Wünsche anpassen und unser Service verbessern. </p>
<p>MailChimp darf zudem diese Daten auch zur Verbesserung des eigenen Service-Dienstes verwenden.  Dadurch kann beispielsweise der Versand technisch optimiert werden oder der Standort (das Land) der  Empfänger bestimmt werden. </p>
<p>MailChimp verwendet auf seinen eigenen Webseiten auch Cookies (kleine Text-Dateien, die Daten auf  Ihrem Browser speichern). Dabei können personenbezogenen Daten durch MailChimp und dessen  Partner (z.B. Google Analytics) verarbeitet werden. Diese Datenerhebung liegt in der Verantwortung von  MailChimp und wir haben darauf keinen Einfluss. Im „Cookie Statement“ von MailChimp (unter:  <a href="https://mailchimp.com/legal/cookies/">https://mailchimp.com/legal/cookies/</a>) erfahren Sie genau, wie und warum das Unternehmen Cookies  verwendet. </p>
<h2 id="wie-lange-und-wo-werden-die-daten-gespeichert">Wie lange und wo werden die Daten gespeichert? </h2>
<p>Da MailChimp ein amerikanisches Unternehmen ist, werden alle gesammelten Daten auch auf  amerikanischen Servern gespeichert. </p>
<p>Grundsätzlich bleiben die Daten auf den Servern von Mailchimp dauerhaft gespeichert und werden erst  gelöscht, wenn eine Aufforderung von Ihnen erfolgt. Sie können Ihren Kontakt bei uns löschen lassen.  Das entfernt für uns dauerhaft all Ihre persönlichen Daten und anonymisiert Sie in den Mailchimp Berichten. Sie können allerdings auch direkt bei MailChimp die Löschung Ihrer Daten anfordern. Dann  werden dort all Ihre Daten entfernt und wir bekommen eine Benachrichtigung von MailChimp. Nachdem  wir die E-Mail erhalten haben, haben wir 30 Tage Zeit, um Ihren Kontakt von allen verbundenen  Integrationen zu löschen. </p>
<h2 id="wie-kann-ich-meine-daten-löschen-bzw-die-datenspeicherung-verhindern">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern? </h2>
<p>Sie können Ihre Zustimmung für den Erhalt unseres Newsletters jederzeit innerhalb der empfangenen E Mail per Klick auf den Link im unteren Bereich entziehen. Wenn Sie sich mit einem Klick auf den  Abmeldelink abgemeldet haben, werden Ihre Daten bei MailChimp gelöscht. </p>
<p>Falls Sie über einen Link in unserem Newsletter auf eine Website von MailChimp gelangen und Cookies in  Ihrem Browser gesetzt werden, können Sie diese Cookies jederzeit wie oben beschrieben löschen oder  deaktivieren.</p>
<p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie  immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie  entscheiden, ob Sie es erlauben oder nicht. </p>
<p>Bitte beachten Sie, dass bei der Verwendung dieses Tools Daten von Ihnen auch außerhalb der EU  gespeichert und verarbeitet werden können. Die meisten Drittstaaten (darunter auch die USA) gelten  nach derzeitigem europäischen Datenschutzrecht als nicht sicher. Daten an unsichere Drittstaaten  dürfen also nicht einfach übertragen, dort gespeichert und verarbeitet werden, sofern es keine  passenden Garantien (wie etwa EU-Standardvertragsklauseln) zwischen uns und dem außereuropäischen  Dienstleister gibt. </p>
<p>Mehr über den Einsatz von Cookies bei MailChimp erfahren Sie auf  <a href="https://mailchimp.com/legal/cookies/">https://mailchimp.com/legal/cookies/</a>.
 Informationen zum Datenschutz bei MailChimp (Privacy) können Sie auf <a href="https://mailchimp.com/legal/privacy/">https://mailchimp.com/legal/privacy/</a> nachlesen. </p>
<h2 id="7-plugins-und-tools"><strong>7. Plugins und Tools</strong></h2>
<h2 id="youtube-mit-erweitertem-datenschutz">YouTube mit erweitertem Datenschutz</h2>
<p>Diese Website bindet Videos der Website YouTube ein. Betreiber der Seiten ist die Google Ireland Limited
(„Google“), Gordon House, Barrow Street, Dublin 4, Irland.</p>
<p>Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus bewirkt laut YouTube, dass
YouTube keine Informationen über die Besucher auf dieser Website speichert, bevor diese sich das Video
ansehen. Die Weitergabe von Daten an YouTube-Partner wird durch den erweiterten Datenschutzmodus
hingegen nicht zwingend ausgeschlossen. So stellt YouTube – unabhängig davon, ob Sie sich ein Video
ansehen – eine Verbindung zum Google DoubleClick-Netzwerk her.
Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine Verbindung zu den Servern von
YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben.
Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie YouTube, Ihr Surfverhalten direkt
Ihrem persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem YouTube-
Account ausloggen.
Des Weiteren kann YouTube nach Starten eines Videos verschiedene Cookies auf Ihrem Endgerät speichern
oder vergleichbare Wiedererkennungstechnologien (z. B. Device-Fingerprinting) einsetzen. Auf diese Weise
kann YouTube Informationen über Besucher dieser Website erhalten. Diese Informationen werden u. a.
verwendet, um Videostatistiken zu erfassen, die Anwenderfreundlichkeit zu verbessern und
Betrugsversuchen vorzubeugen.
Gegebenenfalls können nach dem Start eines YouTube-Videos weitere Datenverarbeitungsvorgänge
ausgelöst werden, auf die wir keinen Einfluss haben.</p>
<p>Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote.
Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende
Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
Einwilligung ist jederzeit widerrufbar.
Weitere Informationen über Datenschutz bei YouTube finden Sie in deren Datenschutzerklärung unter:
<a href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a>.</p>
<pre><code class="language-solidity"><span class="hljs-comment">// SPDX-License-Identifier: MIT</span>
<span class="hljs-meta"><span class="hljs-keyword">pragma</span> <span class="hljs-keyword">solidity</span> ^0.8.13;</span>

<span class="hljs-class"><span class="hljs-keyword">contract</span> <span class="hljs-title">Impressum</span> </span>{
    <span class="hljs-keyword">string</span> <span class="hljs-keyword">public</span> name <span class="hljs-operator">=</span> <span class="hljs-string">"Impressum"</span>;
    <span class="hljs-keyword">string</span> <span class="hljs-keyword">public</span> danke <span class="hljs-operator">=</span> <span class="hljs-string">"Danke fuer dein Interesse"</span>;
    <span class="hljs-keyword">string</span> <span class="hljs-keyword">public</span> frage <span class="hljs-operator">=</span> <span class="hljs-string">"Du hast eine Frage oder eine Verbesserungsvorschlag?"</span>;
    <span class="hljs-keyword">string</span> <span class="hljs-keyword">public</span> contact <span class="hljs-operator">=</span> <span class="hljs-string">"Dann schreib mir eine Email an"</span>;
    <span class="hljs-keyword">string</span> <span class="hljs-keyword">public</span> email <span class="hljs-operator">=</span> <span class="hljs-string">"support@simpleki.de"</span>;
    <span class="hljs-keyword">string</span> <span class="hljs-keyword">public</span> homepage <span class="hljs-operator">=</span> <span class="hljs-string">"www.simpleki.de"</span>;
}
</code></pre>
`

export default html
