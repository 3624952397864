import React from "react"
import SEO from "../components/SEO"
import styles from "./index.module.css"
// import youTube from "../components/youtube.png"
// import { Email } from "../components/Email"
// import { useNewsletterModalContext } from "../contexts/NewsletterContext"
import { useAppContext } from "../contexts/AppContext"
import NewsletterModal from "../components/Email/NewsletterModalSubstack"
import {event} from "../lib/user";
interface Route {
  path: string
  title: string
}

const SOL_ROUTES: Route[] = [
  {
    path: "hello-world",
    title: "Hello World",
  },
  {
    path: "first-app",
    title: "Deine erste App!",
  },
  {
    path: "daten-typen",
    title: "Primitive Daten Typen",
  },
  {
    path: "variables",
    title: "Variablen",
  },
  {
    path: "constants",
    title: "Konstanten",
  },
  {
    path: "immutable",
    title: "Immutable",
  },
  {
    path: "state-variables",
    title: "Zustandsvariablen",
  },
  {
    path: "ether-units",
    title: "Was ist Ether und Wei?",
  },
  {
    path: "gas",
    title: "Was ist Gas und Gas Preis?",
  },
  // Flow control
  {
    path: "if-else",
    title: "If / Else",
  },
  {
    path: "loop",
    title: "For und While Loop",
  },
  // collection data types
  {
    path: "mapping",
    title: "Mapping",
  },
  {
    path: "array",
    title: "Array",
  },
  // custom data types
  {
    path: "enum",
    title: "Enum",
  },
  {
    path: "structs",
    title: "Structs",
  },
  {
    path: "data-locations",
    title: "Was ist Storage, Memory und Calldata?",
  },
  // function
  {
    path: "function",
    title: "Funktionen",
  },
  {
    path: "view-and-pure-functions",
    title: "View und Pure Funktionen",
  },
  {
    path: "error",
    title: "Error",
  },
  {
    path: "function-modifier",
    title: "Funktions Modifier",
  },
  {
    path: "events",
    title: "Events",
  },
  // inheritance
  {
    path: "constructor",
    title: "Konstruktur",
  },
  {
    path: "inheritance",
    title: "Vererbung",
  },
  {
    path: "shadowing-inherited-state-variables",
    title: "Shadowing vererbter Zustandsvariablen",
  },
  {
    path: "super",
    title: "Aufrufen vererbter Funktionen",
  },
  {
    path: "visibility",
    title: "Pure, View und Private",
  },
  {
    path: "interface",
    title: "Interface",
  },
  // send / receive ether
  {
    path: "payable",
    title: "Payable",
  },
  {
    path: "sending-ether",
    title: "Ether senden - Transfer, Send und Call",
  },
  {
    path: "fallback",
    title: "Fallback",
  },
  // contract interaction
  {
    path: "call",
    title: "Call",
  },
  {
    path: "delegatecall",
    title: "Delegatecall",
  },
  {
    path: "function-selector",
    title: "Funktions Selector",
  },

  {
    path: "calling-contract",
    title: "Aufrufen anderer Contracts",
  },
  {
    path: "new-contract",
    title: "Erstelle Contracts in einem Contract",
  },
  // misc
  {
    path: "try-catch",
    title: "Try / Catch",
  },
  {
    path: "import",
    title: "Import",
  },
  {
    path: "library",
    title: "Library",
  },
  {
    path: "abi-decode",
    title: "ABI Decode",
  },
  // crypto
  {
    path: "hashing",
    title: "Hashing mit Keccak256",
  },
  {
    path: "signature",
    title: "Verifiziere Signaturen",
  },
  {
    path: "gas-golf",
    title: "Gas Optimierungen",
  },
]

const APP_ROUTES: Route[] = [
  {
    path: "ether-wallet",
    title: "Ether Wallet",
  },
  {
    path: "multi-sig-wallet",
    title: "Multi Sig Wallet",
  },
  {
    path: "merkle-tree",
    title: "Merkle Tree",
  },
  {
    path: "iterable-mapping",
    title: "Iterable Mapping",
  },
  {
    path: "erc20",
    title: "ERC20",
  },
  {
    path: "erc721",
    title: "ERC721",
  },
  {
    path: "create2",
    title: "Precompute Contract Address with Create2",
  },
  {
    path: "minimal-proxy",
    title: "Minimal Proxy Contract",
  },
  {
    path: "upgradeable-proxy",
    title: "Upgradeable Proxy",
  },
  {
    path: "deploy-any-contract",
    title: "Deploy Any Contract",
  },
  {
    path: "write-to-any-slot",
    title: "Write to Any Slot",
  },
  {
    path: "uni-directional-payment-channel",
    title: "Uni-directional Payment Channel",
  },
  {
    path: "bi-directional-payment-channel",
    title: "Bi-directional Payment Channel",
  },
  {
    path: "english-auction",
    title: "English Auction",
  },
  {
    path: "dutch-auction",
    title: "Dutch Auction",
  },
  {
    path: "crowd-fund",
    title: "Crowd Fund",
  },
  {
    path: "multi-call",
    title: "Multi Call",
  },
  {
    path: "multi-delegatecall",
    title: "Multi Delegatecall",
  },
  {
    path: "time-lock",
    title: "Time Lock",
  },
]

const HACK_ROUTES: Route[] = [
  {
    path: "re-entrancy",
    title: "Re-Entrancy",
  },
  {
    path: "overflow",
    title: "Arithmetic Overflow and Underflow",
  },
  {
    path: "self-destruct",
    title: "Self Destruct",
  },
  {
    path: "accessing-private-data",
    title: "Accessing Private Data",
  },
  {
    path: "delegatecall",
    title: "Delegatecall",
  },
  {
    path: "randomness",
    title: "Source of Randomness",
  },
  {
    path: "denial-of-service",
    title: "Denial of Service",
  },
  {
    path: "phishing-with-tx-origin",
    title: "Phishing with tx.origin",
  },
  {
    path: "hiding-malicious-code-with-external-contract",
    title: "Hiding Malicious Code with External Contract",
  },
  {
    path: "honeypot",
    title: "Honeypot",
  },
  {
    path: "front-running",
    title: "Front Running",
  },
  {
    path: "block-timestamp-manipulation",
    title: "Block Timestamp Manipulation",
  },
  {
    path: "signature-replay",
    title: "Signature Replay",
  },
  {
    path: "contract-size",
    title: "Bypass Contract Size Check",
  },
]

const TEST_ROUTES: Route[] = [
  {
    path: "echidna",
    title: "Echidna",
  },
]

const DEFI_ROUTES = [
  {
    path: "uniswap-v2",
    title: "Uniswap V2 Swap",
  },
  {
    path: "uniswap-v2-add-remove-liquidity",
    title: "Uniswap V2 Add Remove Liquidity",
  },
  {
    path: "uniswap-v2-optimal-one-sided-supply",
    title: "Uniswap V2 Optimal One Sided Supply",
  },
  {
    path: "uniswap-v3-swap",
    title: "Uniswap V3 Swap",
  },
  {
    path: "chainlink-price-oracle",
    title: "Chainlink Price Oracle",
  },
  {
    path: "staking-rewards",
    title: "Staking Rewards",
  },
  {
    path: "vault",
    title: "Vault",
  },
  {
    path: "constant-sum-amm",
    title: "Constant Sum AMM",
  },
  {
    path: "constant-product-amm",
    title: "Constant Product AMM",
  },
  {
    path: "stable-swap-amm",
    title: "Stable Swap AMM",
  },
]

export const ROUTES_BY_CATEGORY = [
  {
    title: "",
    routes: SOL_ROUTES.map((route) => ({
      ...route,
      path: `/${route.path}`,
    })),
  },
  // {
  //   title: "Applications",
  //   routes: APP_ROUTES.map((route) => ({
  //     ...route,
  //     path: `/app/${route.path}`,
  //   })),
  // },
  // {
  //   title: "Hacks",
  //   routes: HACK_ROUTES.map((route) => ({
  //     ...route,
  //     path: `/hacks/${route.path}`,
  //   })),
  // },
  // {
  //   title: "Tests",
  //   routes: TEST_ROUTES.map((route) => ({
  //     ...route,
  //     path: `/tests/${route.path}`,
  //   })),
  // },
  // {
  //   title: "DeFi",
  //   routes: DEFI_ROUTES.map((route) => ({
  //     ...route,
  //     path: `/defi/${route.path}`,
  //   })),
  // },
]

const ROUTES = ROUTES_BY_CATEGORY.map(({ routes }) => routes).flat()
const ROUTE_INDEX_BY_PATH = ROUTES.reduce((map, route: Route, i) => {
  // @ts-ignore
  map[route.path] = i
  return map
}, {})

export function getPrevNextPaths(path: string): {
  prev: Route | null
  next: Route | null
} {
  // @ts-ignore
  const index = ROUTE_INDEX_BY_PATH[path]
  if (index >= 0) {
    const prev = ROUTES[index - 1] || null
    const next = ROUTES[index + 1] || null
    return { prev, next }
  }
  return {
    prev: null,
    next: null,
  }
}

const UPDATES = [
  "2022/07/30 - Uniswap V3 swap examples",
  "2022/07/16 - Update ERC721",
  "2022/07/10 - Update StakingRewards",
]

export default function HomePage() {
  const { state, setNewsletter } = useAppContext()


  return (
    <div className={styles.component}>
      {state.newsletter ? (
        <NewsletterModal onClose={() => setNewsletter(false)} />
      ) : null}
      <SEO
        title="Lerne, wie du Smart Contracts programmierst auf der Ethereum Blockchain mit Solidity Beispielen"
        // description="Lerne wie man Smart Contracts programmiert in der Programmiersprache Solidity anhand von Beispielen"
        description="Lerne, wie du Smart Contracts programmierst auf der Ethereum Blockchain mit Solidity Beispielen"
      />
      <h1 className={styles.header}>
        <a href="/">Lern Solidity anhand einfacher Beispiele</a>
      </h1>
      <div className={styles.subHeader}>
      <strong >Du willst Blockchain Programmierer werden?</strong>
      <div>

        <small> Dann lerne anhand einfacher Beispiele, wie du Smart Contracts mit der Programmiersprache Solidity schreiben kannst. 
        </small></div>
      </div>
      <div className={styles.card}>
        <p>
          Lerne wie du selbst <strong>Smart Contracts</strong> programmierst, mit der Programmiersprache <a href="https://solidity.readthedocs.io">Solidity</a>. Blockchain Programmierung ist kein Hexenwerk. 
        </p>
        <p>
          Die Blockchain-Programmiersprache für <strong>Ethereum</strong> ist Solidity. Sie ist ein Mix aus Python, C++ oder Javascript.
        </p>
        <p>
          Sofern du bereits Erfahrung mit einer den oben genannten Sprachen hast, wirst du dich schnell zurecht finden.
        </p>
        <p> Wenn du noch <strong>keine Erfahrungen</strong> hast, dann würde ich dir empfehlen, den Newsletter zu abonnieren.
        </p>
        <p>Dieser Service wird bereitgestellt von <strong><a href="https://www.simpleki.de">SimpleKI</a></strong></p>
        <p>Viel Spass beim lernen!</p>
      </div>
      <Newsletter />
      <div className={styles.main}>

        {ROUTES_BY_CATEGORY.map(({ routes, title }, i) => (
          <div key={i}>
            {title && <h3 className={styles.category}>{title}</h3>}

            <ul className={styles.list}>
              {routes.map(({ path, title }) => (
                <li className={styles.listItem} key={path}>
                  <a href={path} >{title}</a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <Newsletter />
    </div>
  )
}

function Newsletter() {
  const { state, setNewsletter } = useAppContext()
  console.log(state)

  function showNewsletterModal() {
    setNewsletter(true)
    event("newsletter_open")
  }

  return (
    <div className={styles.cta}>
      <div className={styles.ctaText}>
        <h3>Bist du ein guter Programmierer?</h3>
        <p>Gute Programmierer zeichnen sich dadurch aus, dass Sie immer lernen und auf dem neuesten Stand sind.</p>
        <p>Lass dir nicht die regelmässige Updates zu neuen Beispielen entgehen. </p>
      </div>
      <div>
        <button className={styles.ctaButton} onClick={showNewsletterModal}>
          Abonnieren
        </button>
      </div>
      <div className={styles.ctaText}>
        <p><strong>Psst!</strong> Verpasse nicht den Release des Solidity Videokurses!</p>
      </div>
    </div>
  )
}
